import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../layouts/pl"
import Fade from 'react-reveal/Fade';

// ICONS
import { FaWater } from 'react-icons/fa';
import { TiWeatherSunny } from 'react-icons/ti';
import { TiLocationOutline } from 'react-icons/ti';
import { FaSwimmingPool } from 'react-icons/fa';
import { IoIosFitness } from "react-icons/io";

const Oferta = (props) => {
    const data = useStaticQuery(graphql` 
    query {
        desktopImage: file(relativePath: { eq: "images/photos/oferta/oferta-main.jpg" }) {
            childImageSharp {
                fluid(quality: 100){
                ...GatsbyImageSharpFluid
                }
            }   
        }
        mobileImage: file(relativePath: { eq: "images/photos/oferta/oferta-main-mobile.jpg" }) {
            childImageSharp {
                fluid(quality: 50){
                    ...GatsbyImageSharpFluid
                }
            }   
        }
    }
`);

    const sources = [
        data.mobileImage.childImageSharp.fluid,
        {
            ...data.desktopImage.childImageSharp.fluid,
            media: `(min-width: 768px)`,
        },
    ];


    return (
        <Layout location={props.location}>
            <Fade up>
                <section>
                    <Img fluid={sources} alt="Dziwnowek apartamenty Amber" />
                    <h1 className="title">ANGEBOT</h1>
                    <div className="content-container">
                        <h1>Ideale Sommerferien und unvergessliche Eindrücke</h1>
                        <p>Wir bieten Ihnen ganzjährige, komfortable Appartements AMBER in Dziwnówek und Dziwnów am Meer. Sie sind etwa 250 m oder 700 m von den schönen, sandigen Stränden entfernt.</p>
                        <p>Appartements Amber 1 und Amber 2 befinden sich im Komplet PORTA MARE in Siedlung „Słoneczne Tarasy” in Dziwnówek und Appertement Amber 3 befindet sich im Komplet PORTA MARE Marina in Dziwnów.</p>
                        <p>Appartements befinden sich im ersten, zweiten und dritten Stock. In allen Gebäuden gibt es einen Lift. Die Appartemente sind für 4 oder 6 Personen geeignet, es gibt hier: Wohnzimmer mit voll eingerichteter Küchenecke, ein oder zwei Schlafzimmer, Badezimmer und Balkon, wir bieten Ihnen auch Parkplätze in der Untergrundgarage.</p>
                        <p>Sie bekommen hier auch WiFi und Klimaanlage. In unseren Appartementen AMBER verbringen Sie schön und bequem Ihren Urlaub.</p>
                        <br />
                        <br />
                        <p>Dziwnów und Dziwnówek sind kleine Orte am Meeer, sie sind nur 3 Kilometer voneinander entfernt. Sie erholen sich hier wunderschön</p>
                        <p>Sie liegen am Dziwnów Nehrung zwischen Ostsee und Wrzosowska Bucht.</p>
                        <p>Die grösste Attraktion von Dziwnówek und Dziwnów ist der saubere Ostsee, der breite, sandige Strand und Kieferwälde.</p>
                        <p>Im Gegend kann man toll und aktiv seine Freizeit verbringen: Wassersporte, Fahrräder und Wandern. In der Nähe gibt es auch eine Windsurfingschule und Fahrradvermietung. Schöne Fahrradwege befinden sich im Kieferwald und Meerküste entlang. Man kann sich dort gut erholen.</p>
                        <br />
                    </div>
                </section>
            </Fade>

            <Fade up>
                <section className="facts">
                    <h2>Dziwnówek</h2>
                    <div>
                        <span>
                            <p>250 m</p>
                            <FaWater />
                            <p>zum Meer</p>
                            <span />
                        </span>
                        <span>
                            <p>200 m</p>
                            <TiLocationOutline />
                            <p>zum Stadtzentrum</p>
                            <span />

                        </span>
                        <span>
                            <p>450 m</p>
                            <TiWeatherSunny />
                            <p>zum Stausee</p>
                            <span />

                        </span>
                        <span>
                            <p>300 m</p>
                            <FaSwimmingPool />
                            <p>zum Schwimmbad und SPA</p>
                            <span />

                        </span>
                    </div>
                    <span className='divider' />
                    <h2>Dziwnów</h2>
                    <div>
                        <span>
                            <p>700 </p>
                            <FaWater />
                            <p>zum Meer</p>
                            <span />
                        </span>
                        <span>
                            <p>300 m</p>
                            <TiLocationOutline />
                            <p>zum Stadtzentrum</p>
                            <span />

                        </span>
                        <span>
                            <p>Vorort</p>
                            <FaSwimmingPool />
                            <p>Schwimmbad, Whirlpool und Saunen</p>
                            <span />
                        </span>
                        <span>
                            <p>Vorort</p>
                            <IoIosFitness />
                            <p>Fitnessraum</p>
                            <span />
                        </span>
                    </div>
                </section>
            </Fade>
        </Layout>
    )
}


export default Oferta
